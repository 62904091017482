import { getLinkStyles } from "@helpers/schemas"
import {
  arrowRight,
  calendar2CTA,
  comments,
  download,
  edit,
  envelope,
  externalLink,
  lock,
  university2,
  share,
  themeIconWhatsapp,
  themeIconPhone,
} from "@theme-img"

export default {
  schemaType: "component",
  displayName: "Description Button",
  component: "DescriptionButton",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          type: "LinkField",
          key: "link",
          whiteList: [
            "RequestInfo",
            "FloatingCTAMail",
            "FloatingCTALightbox",
            "FloatingCTAVideo",
          ],
        },
        {
          title: "Auxiliar Text",
          key: "auxText",
          type: "TextField",
          humanReadable: true,
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "style",
          type: "VisualUniqueSelection",
          options: getLinkStyles(),
          columns: 3,
        },
        {
          title: "Show Left Icon",
          type: "ConditionalField",
          key: "showLeftIcon",
          mandatory: true,
          required: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              title: "Icon",
              key: "leftIcon",
              type: "VisualUniqueSelection",
              options: [
                {
                  value: "aDownload",
                  img: download,
                },
                {
                  value: "higher",
                  img: arrowRight,
                },
                {
                  value: "externalLink",
                  img: externalLink,
                },
                {
                  value: "email",
                  img: envelope,
                },
                {
                  value: "comments",
                  img: comments,
                },
                {
                  value: "whatsapp",
                  img: themeIconWhatsapp,
                },
                {
                  value: "phone",
                  img: themeIconPhone,
                },
                {
                  value: "share",
                  img: share,
                },
                {
                  value: "calendar2",
                  img: calendar2CTA,
                },
                {
                  value: "edit2",
                  img: edit,
                },
                {
                  value: "lock",
                  img: lock,
                },
                {
                  value: "university2",
                  img: university2,
                },
              ],
              columns: 6,
              condition: true,
            },
          ],
        },
        {
          title: "Show Rigth Icon",
          type: "ConditionalField",
          key: "showRigthIcon",
          mandatory: true,
          required: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              title: "Icon",
              key: "rightIcon",
              type: "VisualUniqueSelection",
              options: [
                {
                  value: "aDownload",
                  img: download,
                },
                {
                  value: "higher",
                  img: arrowRight,
                },
                {
                  value: "externalLink",
                  img: externalLink,
                },
                {
                  value: "email",
                  img: envelope,
                },
                {
                  value: "comments",
                  img: comments,
                },
                {
                  value: "whatsapp",
                  img: themeIconWhatsapp,
                },
                {
                  value: "phone",
                  img: themeIconPhone,
                },
                {
                  value: "share",
                  img: share,
                },
                {
                  value: "calendar2",
                  img: calendar2CTA,
                },
                {
                  value: "edit2",
                  img: edit,
                },
                {
                  value: "lock",
                  img: lock,
                },
                {
                  value: "university2",
                  img: university2,
                },
              ],
              columns: 6,
              condition: true,
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "DescriptionButton",
    style: "primary",
    showLeftIcon: false,
    showRigthIcon: false,
    auxText: "Auxiliar text",
    link: {
      text: "Link",
      linkType: "url",
      url: {
        href: "",
        linkTo: null,
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
      modal: {
        requestInfo: {
          component: "RequestInfo",
        },
        floatingCTAMail: {
          component: "FloatingCTAMail",
        },
        floatingCTALightbox: {
          component: "FloatingCTALightbox",
        },
        floatingCTAVideo: {
          component: "FloatingCTAVideo",
        },
      },
    },
  },
}
