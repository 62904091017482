import { getThemeOptions } from "@helpers/schemas"
import { ProgramDistributor, ProgramDistributor2x } from "@thumbnails/Modules"
import {
  ProgramDistributorLayout1,
  ProgramDistributorLayout2,
  ProgramDistributorLayout3,
  ProgramDistributorLayout4,
} from "@layouts"

export default {
  schemaType: "module",
  displayName: "Program Distributor",
  component: "ProgramDistributor",
  category: "studies",
  dataPacks: ["STUDIES"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    AudiovisualList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EpicAwardDetail: ["mainContent", "relatedContent"],
    EpicAwardList: ["relatedContent"],
    EventDetail: ["mainContent", "relatedContent"],
    EventList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    NewsDetail: ["relatedContent"],
    NewsList: ["relatedContent"],
    ProgramDetail: ["relatedContent"],
    ProgramTabElement: ["componentModules"],
    ProjectDetail: ["mainContent", "relatedContent"],
    ProjectList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    SpecializationDetail: ["relatedContent"],
    StoriesList: ["relatedContent"],
    StoryDetail: ["mainContent", "relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
          humanReadable: true,
        },
        {
          title: "Programs",
          type: "ReferenceField",
          sources: [
            { structuredData: "PROGRAMS" },
            { structuredData: "SPECIALIZATIONS" },
          ],
          key: "data",
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
        },
        {
          title: "Filter by day",
          type: "ConditionalField",
          key: "filterByDay",
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              title: "Select programs",
              type: "RadioGroup",
              key: "selectProgram",
              condition: true,
              mandatory: false,
              options: [
                {
                  value: "past",
                  title: "Past",
                  name: "past",
                },
                {
                  value: "futures",
                  title: "Futures",
                  name: "futures",
                },
              ],
            },
          ],
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "Cards titles link",
          type: "RadioGroup",
          key: "titlesLink",
          mandatory: false,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Link text",
          type: "TextField",
          key: "linkText",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Where should the link go?",
          type: "RadioGroup",
          key: "linkDirection",
          mandatory: false,
          helptext: "This only affects to automatic mode.",
          options: [
            {
              value: "pageOfCanonical",
              title: "Page of the canonical site",
              name: "pageOfCanonical",
            },
            {
              value: "pageOfSite",
              title: "Page of this site",
              name: "pageOfSite",
            },
          ],
        },
        {
          title: "Cards 'Apply' button",
          type: "RadioGroup",
          key: "applyButton",
          mandatory: true,
          options: [
            {
              name: "no",
              value: false,
              title: "No",
            },
            {
              name: "yes",
              value: true,
              title: "Yes",
            },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          mandatory: true,
          options: [
            {
              value: "L001",
              img: ProgramDistributorLayout1,
            },
            {
              value: "L002",
              img: ProgramDistributorLayout2,
            },
            {
              value: "L003",
              img: ProgramDistributorLayout3,
            },
            {
              value: "L004",
              img: ProgramDistributorLayout4,
            },
          ],
          columns: 4,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "ProgramDistributor",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    layout: "L002",
    title: { tag: "h2", content: "Lorem ipsum" },
    subtitle: null,
    detail: null,
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "PROGRAMS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 4,
      preferenceLanguage: false,
    },
    noResultsText: "Sorry, no results were found.",
    filterByDay: false,
    selectProgram: "futures",
    cardTitleTag: "h3",
    titlesLink: false,
    linkText: null,
    linkDirection: "pageOfSite",
    applyButton: false,
    groupingLink: {
      component: "Link",
      text: "Discover more programs",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    carouselOnMobile: false,
  },
  thumbnails: {
    "1x": ProgramDistributor,
    "2x": ProgramDistributor2x,
  },
}
