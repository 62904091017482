import { StructureTabElement } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Structure Tab Element",
  component: "StructureTabElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          key: "title",
          type: "TextField",
          humanReadable: true,
          mandatory: true,
        },
        {
          title: "Subtitle",
          key: "subtitle",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Main Component",
          type: "ComponentArray",
          mandatory: true,
          whiteList: [
            "StructureTabElement",
            "Accordion",
            "BasicContent",
            "CardCollection",
            "FeaturedAudiovisual",
            "IntroTextCard",
            "LogoCollection",
            "Principles",
            "ProgramsPresentationAuto",
            "Table",
            "TagDistributor",
          ],
          key: "componentModules",
          helptext:
            "For a correct design it should be a group of Structure Tab Elements or other modules",
        },
      ],
    },

    {
      title: "Seo",
      fields: [
        {
          title: "Meta title",
          key: "metaTitle",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Meta description",
          key: "metaDescription",
          type: "TextField",
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "StructureTabElement",
    title: "Tab title",
    subtitle: "",
    metaTitle: "The meta title",
    metaDescription: "The meta description",
    componentModules: [],
  },
  thumbnails: {
    "1x": StructureTabElement,
    "2x": StructureTabElement,
  },
}
