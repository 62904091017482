import { getThemeOptions } from "@helpers/schemas"
import { FeaturedBlock, FeaturedBlock2x } from "@thumbnails/Modules"
import { FeaturedBlockLayout1, FeaturedBlockLayout2 } from "@layouts"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "module",
  displayName: "Featured Block",
  component: "FeaturedBlock",
  category: "featured",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Icon",
          type: "ImageField",
          key: "icon",
          hideable: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Text",
          type: "RichText",
          html: true,
          key: "text",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Main link",
          type: "ComponentContainer",
          key: "primaryLink",
          whiteList: ["DescriptionButton"],
          hideable: true,
        },
        {
          title: "Secondary Link",
          type: "ComponentContainer",
          key: "secondaryLink",
          hideable: true,
          whiteList: ["DescriptionButton"],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: FeaturedBlockLayout1,
            },
            {
              value: "L002",
              img: FeaturedBlockLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "FeaturedBlock",
    anchorID: null,
    theme: "accent",
    verticalSpacing: "medium",
    layout: "L001",
    icon: DEFAULT_IMAGE_FIELD,
    title: {
      content: "Title",
      tag: "h3",
    },
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    primaryLink: {
      component: "DescriptionButton",
      style: "primary",
      showLeftIcon: false,
      showRigthIcon: true,
      rightIcon: "aDownload",
      auxText: "Auxiliar text",
      link: {
        text: "First link",
        linkType: "url",
        url: {
          href: null,
          linkTo: null,
          linkToURL: null,
          newTab: true,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    secondaryLink: {
      component: "DescriptionButton",
    },
  },
  thumbnails: {
    "1x": FeaturedBlock,
    "2x": FeaturedBlock2x,
  },
}
