import dataPacks from "./src/dataPacks/dataPacks"
import dataPacksCategories from "./src/dataPacks/dataPacksCategories"
import structuredData from "./src/dataPacks/structuredData"
import structuredDataTaxonomies from "./src/dataPacks/structuredDataTaxonomies"
import components from "./src/schemas/components"
import modules from "./src/schemas/modules"
import templates from "./src/schemas/templates"

// other schemas
import languages from "./src/dataPacks/languages"
import menus from "./src/schemas/menus"
import menuItems from "./src/schemas/menus/MenuItem"
import allThemes from "./src/themes/config.json"
// import richTextConfig from "./src/schemas/config/RichText"

// CATEGORIES
import { moduleCategories } from "./src/constants"

// themes composition
const themes = allThemes.map(theme => ({
  label: theme.name,
  value: theme.id,
}))

// RichText config
const richTextConfig = {
  paragraphStyles: [
    { label: "Font size 10", className: "fontSize10" },
    { label: "Font size 11", className: "fontSize11" },
    { label: "Font size 12", className: "fontSize12" },
    { label: "Font size 14", className: "fontSize14" },
    { label: "Font size 16", className: "fontSize16" },
    { label: "Font size 18", className: "fontSize18" },
    { label: "Font size 20", className: "fontSize20" },
    { label: "Font size 22", className: "fontSize22" },
    { label: "Font size 24", className: "fontSize24" },
    { label: "Font size 26", className: "fontSize26" },
    { label: "Font size 28", className: "fontSize28" },
  ],
}

export default {
  schemas: {
    config: {
      menus,
      themes,
      languages,
      menuItems,
      moduleCategories,
      richTextConfig,
    },
    ui: {
      components,
      modules,
      templates,
    },
    contentTypes: {
      dataPacks,
      dataPacksCategories,
      structuredData: { ...structuredData, ...structuredDataTaxonomies },
    },
  },
}
