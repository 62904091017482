import React from "react"
import { Component as CoreComponent, mapLibrary } from "@adapters"

const AccordionElement = React.lazy(() =>
  import(
    "./AccordionElement/AccordionElement"
    /* webpackChunkName: "component---src-AccordionElement-js" */
  )
)
const AddressCard = React.lazy(() =>
  import(
    "./AddressCard/AddressCard" /* webpackChunkName: "component---src-AddressCard" */
  )
)
const AddToCalendar = React.lazy(() =>
  import(
    "./AddToCalendar/AddToCalendar" /* webpackChunkName: "component---src-AddToCalendar" */
  )
)
const Advantage = React.lazy(() =>
  import(
    "./Advantage/Advantage" /* webpackChunkName: "component---src-Advantage" */
  )
)
const AnchorLink = React.lazy(() =>
  import(
    "./AnchorLink/AnchorLink" /* webpackChunkName: "component---src-AnchorLink" */
  )
)
const AudiovisualCard = React.lazy(() =>
  import(
    "./AudiovisualCard/AudiovisualCard" /* webpackChunkName: "component---src-AudiovisualCard" */
  )
)
const AutoplayVideo = React.lazy(() =>
  import(
    "./AutoplayVideo/AutoplayVideo" /* webpackChunkName: "component---src-AutoplayVideo" */
  )
)
const Avatar = React.lazy(() =>
  import("./Avatar/Avatar" /* webpackChunkName: "component---src-Avatar" */)
)
const BackgroundLazyLoadingScript = React.lazy(() =>
  import(
    "./BackgroundLazyLoadingScript/BackgroundLazyLoadingScript" /* webpackChunkName: "component---src-BackgroundLazyLoadingScript" */
  )
)
const BasicBoxedCard = React.lazy(() =>
  import(
    "./BasicBoxedCard/BasicBoxedCard" /* webpackChunkName: "component---src-BasicBoxedCard" */
  )
)
const BasicCard = React.lazy(() =>
  import(
    "./BasicCard/BasicCard" /* webpackChunkName: "component---src-BasicCard" */
  )
)
const BasicIconCard = React.lazy(() =>
  import(
    "./BasicIconCard/BasicIconCard" /* webpackChunkName: "component---src-BasicIconCard" */
  )
)
const BlockTitle = React.lazy(() =>
  import(
    "./BlockTitle/BlockTitle" /* webpackChunkName: "component---src-BlockTitle" */
  )
)
const BlogCard = React.lazy(() =>
  import(
    "./BlogCard/BlogCard" /* webpackChunkName: "component---src-BlogCard" */
  )
)
const BluePanel = React.lazy(() =>
  import(
    "./BluePanel/BluePanel" /* webpackChunkName: "component---src-BluePanel" */
  )
)
const BreadCrumb = React.lazy(() =>
  import(
    "./BreadCrumb/BreadCrumb" /* webpackChunkName: "component---src-BreadCrumb" */
  )
)
const Carousel = React.lazy(() =>
  import(
    "./Carousel/Carousel" /* webpackChunkName: "component---src-Carousel" */
  )
)
const CenteredPanel = React.lazy(() =>
  import(
    "./CenteredPanel/CenteredPanel" /* webpackChunkName: "component---src-CenteredPanel" */
  )
)
const Chart = React.lazy(() =>
  import("./Chart/Chart" /* webpackChunkName: "component---src-Chart" */)
)

const CTACard = React.lazy(() =>
  import("./CTACard/CTACard" /* webpackChunkName: "component---src-CTACard" */)
)
const CypherCard = React.lazy(() =>
  import(
    "./CypherCard/CypherCard" /* webpackChunkName: "component---src-CypherCard" */
  )
)
const DescriptionButton = React.lazy(() =>
  import(
    "./DescriptionButton/DescriptionButton" /* webpackChunkName: "component---src-DescriptionButton" */
  )
)
const DownloadDocument = React.lazy(() =>
  import(
    "./DownloadDocument/DownloadDocument" /* webpackChunkName: "component---src-DownloadDocument" */
  )
)
const DummyTemplateZone = React.lazy(() =>
  import(
    "./DummyTemplateZone/DummyTemplateZone" /* webpackChunkName: "component---src-DummyTemplateZone" */
  )
)
const EpicAwardsPaginationCard = React.lazy(() =>
  import(
    "./EpicAwardsPaginationCard/EpicAwardsPaginationCard" /* webpackChunkName: "component---src-EpicAwardsPaginationCard" */
  )
)
const EventCard = React.lazy(() =>
  import(
    "./EventCard/EventCard" /* webpackChunkName: "component---src-EventCard" */
  )
)
const EventPaginationCard = React.lazy(() =>
  import(
    "./EventPaginationCard/EventPaginationCard" /* webpackChunkName: "component---src-EventPaginationCard" */
  )
)
const ExternalEventCard = React.lazy(() =>
  import(
    "./ExternalEventCard/ExternalEventCard" /* webpackChunkName: "component---src-ExternalEventCard" */
  )
)
const FaqElement = React.lazy(() =>
  import(
    "./FaqElement/FaqElement" /* webpackChunkName: "component---src-FaqElement" */
  )
)
const FeaturedProgramCard = React.lazy(() =>
  import(
    "./FeaturedProgramCard/FeaturedProgramCard" /* webpackChunkName: "component---src-FeaturedProgramCard" */
  )
)
const FeaturedText = React.lazy(() =>
  import(
    "./FeaturedText/FeaturedText" /* webpackChunkName: "component---src-FeaturedText" */
  )
)
const FinalStep = React.lazy(() =>
  import(
    "./FinalStep/FinalStep" /* webpackChunkName: "component---src-FinalStep" */
  )
)
const FormButton = React.lazy(() =>
  import(
    "./FormButton/FormButton" /* webpackChunkName: "component---src-FormButton" */
  )
)
const GeneralInfoCard = React.lazy(() =>
  import(
    "./GeneralInfoCard/GeneralInfoCard" /* webpackChunkName: "component---src-GeneralInfoCard" */
  )
)
const GeneralInfoPaginationCard = React.lazy(() =>
  import(
    "./GeneralInfoPaginationCard/GeneralInfoPaginationCard" /* webpackChunkName: "component---src-GeneralInfoPaginationCard" */
  )
)
const GroupingLink = React.lazy(() =>
  import(
    "./GroupingLink/GroupingLink" /* webpackChunkName: "component---src-GroupingLink" */
  )
)
const HeroCard = React.lazy(() =>
  import(
    "./HeroCard/HeroCard" /* webpackChunkName: "component---src-HeroCard" */
  )
)
const HeroMosaicCard = React.lazy(() =>
  import(
    "./HeroMosaicCard/HeroMosaicCard" /* webpackChunkName: "component---src-HeroMosaicCard" */
  )
)
const HeroSideCard = React.lazy(() =>
  import(
    "./HeroSideCard/HeroSideCard" /* webpackChunkName: "component---src-HeroSideCard" */
  )
)
const HorizontalTabElement = React.lazy(() =>
  import(
    "./HorizontalTabElement/HorizontalTabElement" /* webpackChunkName: "component---src-HorizontalTabElement" */
  )
)
const IconCard = React.lazy(() =>
  import(
    "./IconCard/IconCard" /* webpackChunkName: "component---src-IconCard" */
  )
)
const IconCenteredCard = React.lazy(() =>
  import(
    "./IconCenteredCard/IconCenteredCard" /* webpackChunkName: "component---src-IconCenteredCard" */
  )
)
const ImageCard = React.lazy(() =>
  import(
    "./ImageCard/ImageCard" /* webpackChunkName: "component---src-ImageCard" */
  )
)
const ImageCardFluid = React.lazy(() =>
  import(
    "./ImageCardFluid/ImageCardFluid" /* webpackChunkName: "component---src-ImageCardFluid" */
  )
)
const ImageStep = React.lazy(() =>
  import(
    "./ImageStep/ImageStep" /* webpackChunkName: "component---src-ImageStep" */
  )
)
const InsightsCard = React.lazy(() =>
  import(
    "./InsightsCard/InsightsCard" /* webpackChunkName: "component---src-InsightsCard" */
  )
)
const InternalEventCard = React.lazy(() =>
  import(
    "./InternalEventCard/InternalEventCard" /* webpackChunkName: "component---src-InternalEventCard" */
  )
)
const Link = React.lazy(() =>
  import("./Link/Link" /* webpackChunkName: "component---src-Link" */)
)
const LinkedImage = React.lazy(() =>
  import(
    "./LinkedImage/LinkedImage" /* webpackChunkName: "component---src-LinkedImage" */
  )
)
const LinkModal = React.lazy(() =>
  import(
    "./LinkModal/LinkModal" /* webpackChunkName: "component---src-LinkModal" */
  )
)
const LinkWrapper = React.lazy(() =>
  import(
    "./Link/LinkWrapper" /* webpackChunkName: "component---src-LinkWrapper" */
  )
)
const Logo = React.lazy(() =>
  import("./Logo/Logo" /* webpackChunkName: "component---src-Logo" */)
)
const LogoCard = React.lazy(() =>
  import(
    "./LogoCard/LogoCard" /* webpackChunkName: "component---src-LogoCard" */
  )
)
const Map = React.lazy(() =>
  import("./Map/Map" /* webpackChunkName: "component---src-Map" */)
)
const MapCardBox = React.lazy(() =>
  import(
    "./MapCardBox/MapCardBox" /* webpackChunkName: "component---src-MapCardBox" */
  )
)
const MasonryCard = React.lazy(() =>
  import(
    "./MasonryCard/MasonryCard" /* webpackChunkName: "component---src-MasonryCard" */
  )
)
const Milestone = React.lazy(() =>
  import(
    "./Milestone/Milestone" /* webpackChunkName: "component---src-Milestone" */
  )
)
const MilestoneAudiovisual = React.lazy(() =>
  import(
    "./MilestoneAudiovisual/MilestoneAudiovisual" /* webpackChunkName: "component---src-MilestoneAudiovisual" */
  )
)
const MilestoneClaim = React.lazy(() =>
  import(
    "./MilestoneClaim/MilestoneClaim" /* webpackChunkName: "component---src-MilestoneClaim" */
  )
)
const MilestoneCypher = React.lazy(() =>
  import(
    "./MilestoneCypher/MilestoneCypher" /* webpackChunkName: "component---src-MilestoneCypher" */
  )
)
const MilestoneSlide = React.lazy(() =>
  import(
    "./MilestoneSlide/MilestoneSlide" /* webpackChunkName: "component---src-MilestoneSlide" */
  )
)
const MissingComponentMessage = React.lazy(() =>
  import(
    "./MissingComponentMessage/MissingComponentMessage" /* webpackChunkName: "component---src-MissingComponentMessage" */
  )
)
const NavigationBanner = React.lazy(() =>
  import(
    "./NavigationBanner/NavigationBanner" /* webpackChunkName: "component---src-NavigationBanner" */
  )
)
const NewsCard = React.lazy(() =>
  import(
    "./NewsCard/NewsCard" /* webpackChunkName: "component---src-NewsCard" */
  )
)
const PaginationCard = React.lazy(() =>
  import(
    "./PaginationCard/PaginationCard" /* webpackChunkName: "component---src-PaginationCard" */
  )
)
const PersonCard = React.lazy(() =>
  import(
    "./PersonCard/PersonCard" /* webpackChunkName: "component---src-PersonCard" */
  )
)
const PrinciplesCard = React.lazy(() =>
  import(
    "./PrinciplesCard/PrinciplesCard" /* webpackChunkName: "component---src-PrinciplesCard" */
  )
)
const Profile = React.lazy(() =>
  import("./Profile/Profile" /* webpackChunkName: "component---src-Profile" */)
)
const ProgramCard = React.lazy(() =>
  import(
    "./ProgramCard/ProgramCard" /* webpackChunkName: "component---src-ProgramCard" */
  )
)
const ProgramCategory = React.lazy(() =>
  import(
    "./ProgramCategory/ProgramCategory" /* webpackChunkName: "component---src-ProgramCategory" */
  )
)
const ProgramDescription = React.lazy(() =>
  import(
    "./ProgramDescription/ProgramDescription" /* webpackChunkName: "component---src-ProgramDescription" */
  )
)
const ProgramPaginationCard = React.lazy(() =>
  import(
    "./ProgramPaginationCard/ProgramPaginationCard" /* webpackChunkName: "component---src-ProgramPaginationCard" */
  )
)
const ProgramTabElement = React.lazy(() =>
  import(
    "./ProgramTabElement/ProgramTabElement" /* webpackChunkName: "component---src-ProgramTabElement" */
  )
)
const ProjectCard = React.lazy(() =>
  import(
    "./ProjectCard/ProjectCard" /* webpackChunkName: "component---src-ProjectCard" */
  )
)
const PublicationCard = React.lazy(() =>
  import(
    "./PublicationCard/PublicationCard" /* webpackChunkName: "component---src-PublicationCard" */
  )
)
const QuickFact = React.lazy(() =>
  import(
    "./QuickFact/QuickFact" /* webpackChunkName: "component---src-QuickFact" */
  )
)
const QuickFactElement = React.lazy(() =>
  import(
    "./QuickFactElement/QuickFactElement" /* webpackChunkName: "component---src-QuickFactElement" */
  )
)
const Quote = React.lazy(() =>
  import("./Quote/Quote" /* webpackChunkName: "component---src-Quote" */)
)

const ScholarshipCard = React.lazy(() =>
  import(
    "./ScholarshipCard/ScholarshipCard" /* webpackChunkName: "component---src-ScholarshipCard" */
  )
)
const ShowOnScroll = React.lazy(() =>
  import(
    "./ShowOnScroll/ShowOnScroll" /* webpackChunkName: "component---src-ShowOnScroll" */
  )
)
const Slide = React.lazy(() =>
  import("./Slide/Slide" /* webpackChunkName: "component---src-Slide" */)
)
const SlimSocialShare = React.lazy(() =>
  import(
    "./SlimSocialShare/SlimSocialShare" /* webpackChunkName: "component---src-SlimSocialShare" */
  )
)
const SocialIcons = React.lazy(() =>
  import(
    "./SocialIcons/SocialIcons" /* webpackChunkName: "component---src-SocialIcons" */
  )
)
const StepCard = React.lazy(() =>
  import(
    "./StepCard/StepCard" /* webpackChunkName: "component---src-StepCard" */
  )
)
const StepTabElement = React.lazy(() =>
  import(
    "./StepTabElement/StepTabElement" /* webpackChunkName: "component---src-StepTabElement" */
  )
)
const StepTabs = React.lazy(() =>
  import(
    "./StepTabs/StepTabs" /* webpackChunkName: "component---src-StepTabs" */
  )
)
const StoryCard = React.lazy(() =>
  import(
    "./StoryCard/StoryCard" /* webpackChunkName: "component---src-StoryCard" */
  )
)
const StoryCardD002 = React.lazy(() =>
  import(
    "./StoryCard/StoryCardD002" /* webpackChunkName: "component---src-StoryCardD002" */
  )
)
const StoryPaginationCard = React.lazy(() =>
  import(
    "./StoryPaginationCard/StoryPaginationCard" /* webpackChunkName: "component---src-StoryPaginationCard" */
  )
)
const TabSlide = React.lazy(() =>
  import(
    "./TabSlide/TabSlide" /* webpackChunkName: "component---src-TabSlide" */
  )
)
const Tag = React.lazy(() =>
  import("./Tag/Tag" /* webpackChunkName: "component---src-Tag" */)
)
const TagElement = React.lazy(() =>
  import(
    "./TagElement/TagElement" /* webpackChunkName: "component---src-TagElement" */
  )
)
const TagsCloud = React.lazy(() =>
  import(
    "./TagsCloud/TagsCloud" /* webpackChunkName: "component---src-TagsCloud" */
  )
)
const TeacherPaginationCard = React.lazy(() =>
  import(
    "./TeacherPaginationCard/TeacherPaginationCard" /* webpackChunkName: "component---src-TeacherPaginationCard" */
  )
)
const TextStep = React.lazy(() =>
  import(
    "./TextStep/TextStep" /* webpackChunkName: "component---src-TextStep" */
  )
)
const TextualPanel = React.lazy(() =>
  import(
    "./TextualPanel/TextualPanel" /* webpackChunkName: "component---src-TextualPanel" */
  )
)
const UniversalCard = React.lazy(() =>
  import(
    "./UniversalCard/UniversalCard" /* webpackChunkName: "component---src-UniversalCard" */
  )
)
const ValueCard = React.lazy(() =>
  import(
    "./ValueCard/ValueCard" /* webpackChunkName: "component---src-ValueCard" */
  )
)
const VerticalSlide = React.lazy(() =>
  import(
    "./VerticalSlide/VerticalSlide" /* webpackChunkName: "component---src-VerticalSlide" */
  )
)
const VerticalTabElement = React.lazy(() =>
  import(
    "./VerticalTabElement/VerticalTabElement" /* webpackChunkName: "component---src-VerticalTabElement" */
  )
)
const VideoButton = React.lazy(() =>
  import(
    "./VideoButton/VideoButton" /* webpackChunkName: "component---src-VideoButton" */
  )
)
const VideoTextCard = React.lazy(() =>
  import(
    "./VideoTextCard/VideoTextCard" /* webpackChunkName: "component---src-VideoTextCard" */
  )
)
const VideoTextCardFluid = React.lazy(() =>
  import(
    "./VideoTextCardFluid/VideoTextCardFluid" /* webpackChunkName: "component---src-VideoTextCardFluid" */
  )
)
const VideoTitle = React.lazy(() =>
  import(
    "./VideoTitle/VideoTitle" /* webpackChunkName: "component---src-VideoTitle" */
  )
)

const EXPORTS = {
  AccordionElement,
  AddressCard,
  AddToCalendar,
  Advantage,
  AnchorLink,
  AudiovisualCard,
  AutoplayVideo,
  Avatar,
  BackgroundLazyLoadingScript,
  BasicBoxedCard,
  BasicCard,
  BasicIconCard,
  BlockTitle,
  BlogCard,
  BluePanel,
  BreadCrumb,
  Carousel,
  CenteredPanel,
  Chart,
  CTACard,
  CypherCard,
  DescriptionButton,
  DownloadDocument,
  DummyTemplateZone,
  EpicAwardsPaginationCard,
  EventCard,
  EventPaginationCard,
  ExternalEventCard,
  FaqElement,
  FeaturedProgramCard,
  FeaturedText,
  FinalStep,
  FormButton,
  GeneralInfoCard,
  GeneralInfoPaginationCard,
  GroupingLink,
  HeroCard,
  HeroMosaicCard,
  HeroSideCard,
  HorizontalTabElement,
  IconCard,
  IconCenteredCard,
  ImageCard,
  ImageCardFluid,
  ImageStep,
  InsightsCard,
  InternalEventCard,
  Link,
  LinkedImage,
  LinkModal,
  LinkWrapper,
  Logo,
  LogoCard,
  Map,
  MapCardBox,
  MasonryCard,
  Milestone,
  MilestoneAudiovisual,
  MilestoneClaim,
  MilestoneCypher,
  MilestoneSlide,
  MissingComponentMessage,
  NavigationBanner,
  NewsCard,
  PaginationCard,
  PersonCard,
  PrinciplesCard,
  Profile,
  ProgramCard,
  ProgramCategory,
  ProgramDescription,
  ProgramPaginationCard,
  ProgramTabElement,
  ProjectCard,
  PublicationCard,
  QuickFact,
  QuickFactElement,
  Quote,
  ScholarshipCard,
  ShowOnScroll,
  Slide,
  SlimSocialShare,
  SocialIcons,
  StepCard,
  StepTabElement,
  StepTabs,
  StoryCard,
  StoryCardD002,
  StoryPaginationCard,
  TabSlide,
  Tag,
  TagElement,
  TagsCloud,
  TeacherPaginationCard,
  TextStep,
  TextualPanel,
  UniversalCard,
  ValueCard,
  VerticalSlide,
  VerticalTabElement,
  VideoButton,
  VideoTextCard,
  VideoTextCardFluid,
  VideoTitle,
}

const components = mapLibrary({
  ...EXPORTS,
})

const Component = props => (
  <React.Suspense fallback={null}>
    <CoreComponent libComponents={components} {...props} />
  </React.Suspense>
)

export default EXPORTS
export {
  AccordionElement,
  AddressCard,
  AddToCalendar,
  Advantage,
  AnchorLink,
  AudiovisualCard,
  AutoplayVideo,
  Avatar,
  BackgroundLazyLoadingScript,
  BasicBoxedCard,
  BasicCard,
  BasicIconCard,
  BlockTitle,
  BlogCard,
  BluePanel,
  BreadCrumb,
  Carousel,
  CenteredPanel,
  Chart,
  Component,
  CTACard,
  CypherCard,
  DescriptionButton,
  DownloadDocument,
  DummyTemplateZone,
  EpicAwardsPaginationCard,
  EventCard,
  EventPaginationCard,
  ExternalEventCard,
  FaqElement,
  FeaturedProgramCard,
  FeaturedText,
  FinalStep,
  FormButton,
  GeneralInfoCard,
  GeneralInfoPaginationCard,
  GroupingLink,
  HeroCard,
  HeroMosaicCard,
  HeroSideCard,
  HorizontalTabElement,
  IconCard,
  IconCenteredCard,
  ImageCard,
  ImageCardFluid,
  ImageStep,
  InsightsCard,
  InternalEventCard,
  Link,
  LinkedImage,
  LinkModal,
  LinkWrapper,
  Logo,
  LogoCard,
  Map,
  MapCardBox,
  MasonryCard,
  Milestone,
  MilestoneAudiovisual,
  MilestoneClaim,
  MilestoneCypher,
  MilestoneSlide,
  MissingComponentMessage,
  NavigationBanner,
  NewsCard,
  PaginationCard,
  PersonCard,
  PrinciplesCard,
  Profile,
  ProgramCategory,
  ProgramDescription,
  ProgramPaginationCard,
  ProgramTabElement,
  ProjectCard,
  PublicationCard,
  QuickFact,
  QuickFactElement,
  Quote,
  ScholarshipCard,
  ShowOnScroll,
  Slide,
  SlimSocialShare,
  SocialIcons,
  StepCard,
  StepTabElement,
  StepTabs,
  StoryCard,
  StoryCardD002,
  StoryPaginationCard,
  TabSlide,
  Tag,
  TagElement,
  TagsCloud,
  TeacherPaginationCard,
  TextStep,
  TextualPanel,
  UniversalCard,
  ValueCard,
  VerticalSlide,
  VerticalTabElement,
  VideoButton,
  VideoTextCard,
  VideoTextCardFluid,
  VideoTitle,
}
